import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles } from "@material-ui/styles";
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ClearIcon from '@material-ui/icons/Clear';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

import { HealthStatusEnum } from '@comsel/corona';
import { Theme } from '@material-ui/core/styles/createTheme';


const styles = (theme: Theme) => createStyles({
  statusLight: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  statusIcon: {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    lineHeight: '20px',
    padding: '2px',
    textAlign: 'center',
    color: '#fff',
  },
  unknown: {
    background: theme.palette.grey[500],
  },
  ok: {
    background: green[600],
  },
  warning: {
    background: yellow[600],
  },
  error: {
    background: theme.palette.error.main,
  },
});

type Props = WithStyles<typeof styles> & {
  status: HealthStatusEnum,
  tooltip?: string,
};
type State = {};

export const Statuslight = withStyles(styles)(
  class extends Component<Props, State> {
    render() {
      const {classes } = this.props;

      let icon = <HourglassEmptyIcon className={`${classes.statusIcon} ${classes.unknown}`} />;
      if (this.props.status === HealthStatusEnum.WARNING) {
        icon = <PriorityHighIcon  className={`${classes.statusIcon} ${classes.warning}`} />
      }
      if (this.props.status === HealthStatusEnum.ERROR) {
        icon = <ClearIcon className={`${classes.statusIcon} ${classes.error}`}  />
      }
      if (this.props.status === HealthStatusEnum.OK) {
        icon = <CheckIcon className={`${classes.statusIcon} ${classes.ok}`} />
      }
      const Light = () => <div className={classes.statusLight}>
        { icon }
        <div>{ this.props.children }</div>
      </div>


      if (this.props.tooltip==null) {
        return <Light />
      }
      return <Tooltip title={this.props.tooltip}>{Light()}</Tooltip>
    }
  }
)
