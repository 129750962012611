import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function OculusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.21429 2C4.99137 2 4 2.99492 4 4.22222V19.7778C4 21.0051 4.99137 22 6.21429 22H17.2857C18.5086 22 19.5 21.0051 19.5 19.7778V4.22222C19.5 2.99492 18.5086 2 17.2857 2H6.21429ZM11.75 13.7778C14.0735 13.7778 15.9571 11.8874 15.9571 9.55556C15.9571 7.22369 14.0735 5.33333 11.75 5.33333C9.42646 5.33333 7.54286 7.22369 7.54286 9.55556C7.54286 11.8874 9.42646 13.7778 11.75 13.7778Z" />
      <path d="M9.53577 9.55555C9.53577 8.9419 10.0315 8.44444 10.6429 8.44444H12.8572C13.4687 8.44444 13.9643 8.9419 13.9643 9.55555V9.55555C13.9643 10.1692 13.4687 10.6667 12.8572 10.6667H10.6429C10.0315 10.6667 9.53577 10.1692 9.53577 9.55555V9.55555Z" />
    </SvgIcon>
  );
}

export default OculusIcon;