import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function GeminiIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 4.22222C2 2.99492 2.99492 2 4.22222 2H19.7778C21.0051 2 22 2.99492 22 4.22222V19.7778C22 21.0051 21.0051 22 19.7778 22H4.22222C2.99492 22 2 21.0051 2 19.7778V4.22222ZM19.7778 9.77778C19.7778 11.0051 18.7829 12 17.5556 12C16.3283 12 15.3333 11.0051 15.3333 9.77778C15.3333 8.55048 16.3283 7.55556 17.5556 7.55556C18.7829 7.55556 19.7778 8.55048 19.7778 9.77778ZM5.33333 10.8889C5.94698 10.8889 6.44444 10.3914 6.44444 9.77778C6.44444 9.16413 5.94698 8.66667 5.33333 8.66667C4.71968 8.66667 4.22222 9.16413 4.22222 9.77778C4.22222 10.3914 4.71968 10.8889 5.33333 10.8889ZM10.8889 9.77778C10.8889 10.3914 10.3914 10.8889 9.77778 10.8889C9.16413 10.8889 8.66667 10.3914 8.66667 9.77778C8.66667 9.16413 9.16413 8.66667 9.77778 8.66667C10.3914 8.66667 10.8889 9.16413 10.8889 9.77778ZM19.7778 14.2222H4.22222V19.7778H19.7778V14.2222Z" />
    </SvgIcon>
  );
}

export default GeminiIcon;