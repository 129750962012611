import React from 'react';

import PublicIcon from '@material-ui/icons/Public';
import HomeIcon from '@material-ui/icons/Home';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import StarIcon from '@material-ui/icons/Star';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MemoryIcon from '@material-ui/icons/Memory';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import OpacityIcon from '@material-ui/icons/Opacity';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import EVStationIcon from '@material-ui/icons/EvStation';
import SpeedIcon from '@material-ui/icons/Speed';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DeleteIcon from '@material-ui/icons/Delete';
import IsoIcon from '@material-ui/icons/Iso';

import OculusIcon  from './icons/devices/OculusIcon';
import ZodiacIcon  from './icons/devices/ZodiacIcon';
import GeminiIcon  from './icons/devices/GeminiIcon';
import PVArrayIcon from './icons/devices/PVArrayIcon';
import CIcon       from './icons/devices/CIcon';
import ThermometerIcon from './icons/devices/ThermometerIcon';

import { DeviceType, GraphNode, Unit, Model, Medium } from "@comsel/corona";

export class DeviceIcon extends React.Component<{type: GraphNode|Unit, className: any|undefined}, {}> {
  render() {
    function typeToIcon(type: DeviceType, model: Model, className: any = undefined) {
      if (type===DeviceType.Root) {
        return <PublicIcon className={className} />
      }
      if (type===DeviceType.UsagePoint) {
        return <HomeIcon className={className} />
      }
      if (type===DeviceType.Asset) { // change to something else
        return <DeviceHubIcon className={className} />
      }
      if (type===DeviceType.Logging) { // get better icon
        if (model==null || model.model==null) {
          return <PhoneInTalkIcon className={className} />
        }
        if (model.model.startsWith("O3")) {
          return <OculusIcon className={className} />
        }
        if (model.model.startsWith("zoculus-oculus")) {
          return <OculusIcon className={className} />
        }
        if (model.model.startsWith("oculusb")) {
          return <OculusIcon className={className} />
        }
        if (model.model.startsWith("Z3")) {
          return <ZodiacIcon className={className} />
        }
        if (model.model.startsWith("zoculus-zodiac")) {
          return <ZodiacIcon className={className} />
        }
        if (model.model.startsWith("zodiac")) {
          return <ZodiacIcon className={className} />
        }
        if (model.model.startsWith("G3")) {
          return <GeminiIcon className={className} />
        }
        if (model.model.startsWith("gemini")) {
          return <GeminiIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("evse")) {
          return <EVStationIcon className={className} />
        }
        return <PhoneInTalkIcon className={className} />
      }
      if (type===DeviceType.Meter) { // get better icon
        if (model==null || model.model==null) {
          return <MemoryIcon className={className} />
        }
        // try to use medium when available:
        if (model.medium == Medium.Electricity) {
          return <FlashOnIcon className={className} />
        }
        if (model.medium == Medium.Gas) {
          return <WhatsHotIcon className={className} />
        }
        if (model.medium == Medium.Heat) {
          return <WhatsHotIcon className={className} />
        }
        if (model.medium == Medium.Steam) {
          return <WhatsHotIcon className={className} />
        }
        if (model.medium == Medium.WarmWater) {
          return <OpacityIcon className={className} />
        }
        if (model.medium == Medium.Water) {
          return <OpacityIcon className={className} />
        }
        if (model.medium == Medium.HCA) {
          return <WhatsHotIcon className={className} />
        }
        if (model.medium == Medium.CompressedAir) {
          return <SpeedIcon className={className} />
        }
        if (model.medium == Medium.Cooling) {
          return <AcUnitIcon className={className} />
        }
        if (model.medium == Medium.CoolingMode2) {
          return <AcUnitIcon className={className} />
        }
        if (model.medium == Medium.HeatMode2) {
          return <WhatsHotIcon className={className} />
        }
        if (model.medium == Medium.HeatCoolingLoad) {
          return <WhatsHotIcon className={className} />
        }
        if (model.medium == Medium.Bus) {
          // We generally dont want to show the bus icon so we do some hacks here
          if (model.manufacturer == "Endress+Hauser") {
            return <WhatsHotIcon className={className} />
          }
          return <DirectionsBusIcon className={className} />
        }
        /*if (model.medium == Medium.Unknown) {
          return <MemoryIcon className={className} />
        }*/
        if (model.medium == Medium.CalorificValue) {
          return <WhatsHotIcon className={className} />
        }
        if (model.medium == Medium.HotWater) {
          return <OpacityIcon className={className} />
        }
        if (model.medium == Medium.ColdWater) {
          return <OpacityIcon className={className} />
        }
        if (model.medium == Medium.DualWater) {
          return <OpacityIcon className={className} />
        }
        if (model.medium == Medium.Pressure) {
          return <SpeedIcon className={className} />
        }
        if (model.medium == Medium.AdConverter) {
          return <MemoryIcon className={className} />
        }
        if (model.medium == Medium.SmokeDetector) {
          return <SmokingRoomsIcon className={className} />
        }
        if (model.medium == Medium.RoomSensor) {
          return <ThermometerIcon className={className} />
        }
        if (model.medium == Medium.GasDetector) {
          return <SmokingRoomsIcon className={className} />
        }
        if (model.medium == Medium.ReservedSensors1) {
          return <IsoIcon className={className} />
        }
        if (model.medium == Medium.ReservedSensors2) {
          return <ThermometerIcon className={className} />
        }
        if (model.medium == Medium.ReservedSensors3) {
          return <ThermometerIcon className={className} />
        }
        if (model.medium == Medium.Breaker) {
          return <PowerSettingsNewIcon className={className} />
        }
        if (model.medium == Medium.Valve) {
          return <OpacityIcon className={className} />
        }
        if (model.medium == Medium.ReservedSwitching0) {
          return <PowerSettingsNewIcon className={className} />
        }
        if (model.medium == Medium.ReservedSwitching1) {
          return <PowerSettingsNewIcon className={className} />
        }
        if (model.medium == Medium.ReservedSwitching2) {
          return <PowerSettingsNewIcon className={className} />
        }
        if (model.medium == Medium.WasteWater) {
          return <OpacityIcon className={className} />
        }
        if (model.medium == Medium.Garbage) {
          return <DeleteIcon className={className} />
        }
        if (model.medium == Medium.CarbonDioxide) {
          return <ThermometerIcon className={className} />
        }
        if (model.medium == Medium.Environmental) {
          return <ThermometerIcon className={className} />
        }
        if (model.medium == Medium.Inverter) {
          return <PVArrayIcon className={className} />
        }



        if (model.model.startsWith("Carlo")) {
          return <FlashOnIcon className={className} />
        }
        if (model.model.startsWith("Landis+Gyr Ultra")) {
          return <WhatsHotIcon className={className} />
        }
        if (model.model.startsWith("Landis+Gyr")) {
          return <FlashOnIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("emh")) {
          return <FlashOnIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("zpa")) {
          return <FlashOnIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("axi")) {
          return <OpacityIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("sen")) {
          return <OpacityIcon className={className} />
        }
        if (model.model.startsWith("Kamstrup")) {
          return <WhatsHotIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("emo")) {
          return <WhatsHotIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("lan")) {
          return <ThermometerIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("wep")) {
          return <ThermometerIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("elvaco cma")) {
          return <ThermometerIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("tesla")) {
          return <EVStationIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("tsl")) {
          return <EVStationIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("fronius")) {
          return <PVArrayIcon className={className} />
        }
        if (model.model.toLowerCase().startsWith("symo")) {
          return <PVArrayIcon className={className} />
        }
        return <MemoryIcon className={className} />
      }
      if (type==DeviceType.Virtual) {
        return <CIcon className={className} />
      }
      return <StarIcon className={className} />
    }
    return typeToIcon(this.props.type.deviceType, this.props.type.model, this.props.className);
  }
}
