import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function CIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.6334 14.6133L13.1141 14.2667C13.7196 13.9111 14.1469 13.7333 14.3962 13.7333C14.9482 13.7333 15.5447 14.2311 16.1857 15.2267C16.5774 15.8489 16.7733 16.3467 16.7733 16.72C16.7733 17.0933 16.6486 17.4044 16.3994 17.6533C16.1679 17.9022 15.8919 18.1244 15.5714 18.32C15.2509 18.5156 14.9393 18.7022 14.6366 18.88C14.3517 19.0578 13.7552 19.2889 12.8471 19.5733C11.939 19.8578 11.0576 20 10.2029 20C9.36598 20 8.51129 19.8933 7.63879 19.68C6.7841 19.4489 5.8938 19.0756 4.96789 18.56C4.05978 18.0267 3.2407 17.3867 2.51065 16.64C1.7806 15.8756 1.1752 14.9067 0.694436 13.7333C0.231479 12.5422 0 11.2533 0 9.86667C0 8.48 0.231479 7.22667 0.694436 6.10667C1.15739 4.96889 1.74499 4.03555 2.45723 3.30667C3.18728 2.56 4.01526 1.93778 4.94118 1.44C6.68617 0.479999 8.44006 0 10.2029 0C11.0397 0 11.8855 0.133333 12.7402 0.399999C13.6127 0.648888 14.2715 0.906666 14.7167 1.17333L15.3577 1.54667C15.6782 1.74222 15.9275 1.90222 16.1056 2.02667C16.5685 2.4 16.8 2.82667 16.8 3.30667C16.8 3.76889 16.613 4.25778 16.2391 4.77333C15.5447 5.73333 14.9215 6.21333 14.3695 6.21333C14.049 6.21333 13.5949 6.01778 13.0073 5.62667C12.2595 5.02222 11.2623 4.72 10.0159 4.72C8.8585 4.72 7.71892 5.12 6.59714 5.92C6.06296 6.31111 5.61781 6.86222 5.26169 7.57333C4.90556 8.28444 4.7275 9.09333 4.7275 10C4.7275 10.8889 4.90556 11.6889 5.26169 12.4C5.61781 13.1111 6.07186 13.6711 6.62385 14.08C7.71002 14.8622 8.8407 15.2533 10.0159 15.2533C10.5679 15.2533 11.0843 15.1822 11.565 15.04C12.0636 14.8978 12.4197 14.7556 12.6334 14.6133Z"/>
    </SvgIcon>
  );
}

export default CIcon;