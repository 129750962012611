import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles } from "@material-ui/styles";
import { Theme } from '@material-ui/core/styles/createTheme';

import { MuiPickersUtilsProvider, DatePicker, DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import {Duration, DateTime} from 'luxon'


const styles = (theme: Theme) => createStyles({
  pickerContainer: {
    display: 'flex',
    alignContent: 'center'
  },
  label: {
    alignSelf: 'center',
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(2)
  }
});

type OwnProps = {
  label: string,
  resolution: Duration,
  value: Date,
  onChange: (date: any) => void,
  max?: Date,
  min?: Date,
  helperText?: string,
  minDate?: Date,
  maxDate?: Date
}

type Props = WithStyles<typeof styles> & OwnProps;
type State = {};

export const DateTimePicker = withStyles(styles)(
  class extends Component<Props, State> {
    resolutionToUnit() {
      if (Math.floor(this.props.resolution.shiftTo("years").years)>0) {
        return "year"
      } else if (Math.floor(this.props.resolution.shiftTo("months").months)>0) {
        return "month"
      } else if (Math.floor(this.props.resolution.shiftTo("days").days)>0) {
        return "day"
      }
      return "second"
    }
    flatten(time: any): any {
      if (this.props.resolution.days<1) {
        return time;
      }
      let t: DateTime;
      if (time instanceof Date) {
        t = DateTime.fromJSDate(time);
      } else {
        t = time;
      }
      return t.startOf(this.resolutionToUnit());
    }
    years() {
      return <DatePicker
      variant="inline"
      openTo="year"
      style={{width: '50px'}}
      views={["year"]}
      value={this.flatten(this.props.value)}
      onChange={this.props.onChange}
      autoOk={true}
      maxDate={this.props.maxDate}
      minDate={this.props.minDate}
    />
    }
    months() {
      return <DatePicker
      variant="inline"
      openTo="month"
      style={{width: '120px'}}
      views={["year", "month"]}
      value={this.flatten(this.props.value)}
      onChange={this.props.onChange}
      autoOk={true}
      maxDate={this.props.maxDate}
      minDate={this.props.minDate}
    />
    }
    days() {
      return <DatePicker
      variant="inline"
      openTo="date"
      style={{width: '100px'}}
      views={["year", "month", "date"]}
      value={this.flatten(this.props.value)}
      onChange={this.props.onChange}
      autoOk={true}
      maxDate={this.props.maxDate}
      minDate={this.props.minDate}
    />
    }
    time() {
      return <MuiDateTimePicker
      variant="inline"
      ampm={false}
      style={{width: '150px'}}
      value={this.flatten(this.props.value)}
      onChange={this.props.onChange}
      maxDate={this.props.maxDate}
      minDate={this.props.minDate}
    />
    }
    pickPicker() {
      let unit = this.resolutionToUnit();
      if (unit==="year") {
        return this.years();
      } else if (unit==="month") {
        return this.months();
      } else if (unit==="day") {
        return this.days();
      }
      return this.time();
    }
    render() {
      const {classes } = this.props;
      return <div className={classes.pickerContainer}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <div className={classes.label}>{this.props.label}</div>
          {this.pickPicker()}
        </MuiPickersUtilsProvider>
      </div>
    }
  }
)
