import React, { useEffect, useState, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import SearchIcon from '@material-ui/icons/Search';

import { DeviceIcon } from './DeviceIcon';
import { Unit } from '@comsel/corona';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchRoot: {
      marginRight: theme.spacing(2),
      width: '100%',
    },
    search: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(4),
      marginLeft: theme.spacing(1),
    },
    loadingIcon: {
      marginRight: theme.spacing(1),
    },
    autocompleteBox: {
      width: '100%',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 150,
        '&:focus': {
          width: 250,
        },
      },
    },
  })
);

interface Props {
  variant: 'search' | 'standard';
  placeholder: string;
  onUnitSelect: (u: Unit) => void;
  focus?: boolean;
  filter?: string;
  value?: string;
  loading?: boolean,
  onInputChange: (s: string) => void; // Text input
  onChange: () => void; // Input field
  options: Array<Unit>,
}


export const OmniInput = (props: Props) => {
  const classes = useStyles();
  const [hasFocused, setHasFocused] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    const unfocus = () => {
      setHasFocused(false);
    };
    if (props.focus && !hasFocused) {
      if (inputRef != null && inputRef.current != null) {
        console.log('Focus requested and omniinput is live');
        (inputRef as any).current.focus();
        setHasFocused(true);
        setTimeout(unfocus, 1000);
      } else {
        console.log('Focus requested and omniinput is not focusable', inputRef);
      }
    }
    return unfocus;
  }, [props.focus, hasFocused, inputRef]);

  /*
  useEffect(() => {
    const delayedGetUnits = () => {
      if (filter.length > 0) {
        get(cred, new Date(0), [], filter).then(units => {
          if (filter.length > 0) {
            setOptions(units);
          } else {
            setOptions([]);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    };
    if (filter.length > 1 && cred.valid()) {
      setLoading(true);
      const unitsTo = setTimeout(delayedGetUnits, 250);
      return () => clearTimeout(unitsTo);
    } else {
      if (!cred.valid()) {
        console.log(
          'Searching of devices failed because credentials are invalid'
        );
      }
      setOptions([]);
    }
    return () => {};
  }, [filter, cred]);
  */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onInputChange(event.target.value);
    //setValue(event.target.value);
  };

  // OK, calls nothing
  const handleAutocompleteChange = (_event: React.ChangeEvent<{}>, value: Unit | string | null) => {
    if (value != null) {
      props.onUnitSelect(value as Unit);
    }
  };
  const handleAutocompleteInputChange = ( _event: React.ChangeEvent<{}>, value: string, _reason: string) => {
    // this function is an onKeyUp callback so we should avoid blocking, hence the setTimeout 0 thing
    if (value.length < 2) { // clear everything?
      props.onInputChange(value);
      //setValue(value);
    }
  };

  const filterOptions = createFilterOptions({
    limit: 200,
    stringify: (option: Unit) => JSON.stringify(option.encode()),
  });

  const SearchVariantField = (params: any) => (
    <TextField
      {...params}
      placeholder={props.placeholder}
      fullWidth
      onChange={handleChange}
      inputRef={inputRef}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        className: classes.search,
        classes: { root: classes.inputRoot, input: classes.inputInput },
        startAdornment: (
          <>
            <SearchIcon className={classes.searchIcon} />
            {params.InputProps.startAdornment}
          </>
        ),
        endAdornment: (
          <React.Fragment>
            {props.loading ? (
              <CircularProgress
                color="inherit"
                size={20}
                className={classes.loadingIcon}
              />
            ) : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  );

  const StandardVariantField = (params: any/*RenderInputParams*/) => (
    <TextField
      {...params}
      placeholder={props.placeholder}
      fullWidth
      onChange={handleChange}
      inputRef={inputRef}
      InputProps={{
        ...params.InputProps,
        classes: { root: classes.inputRoot },
        startAdornment: (
          <>
            <SearchIcon className={classes.searchIcon} />
            {params.InputProps.startAdornment}
          </>
        ),
        endAdornment: (
          <React.Fragment>
            {props.loading ? (
              <CircularProgress
                color="inherit"
                size={20}
                className={classes.loadingIcon}
              />
            ) : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  );

  return (
    <div className={classes.searchRoot}>
      <Autocomplete
        freeSolo
        clearOnEscape
        loading={props.loading}
        options={props.options}
        getOptionLabel={o => o != null ? o.assetid + ' (' + o.uuid.string() + ')' : ''}
        filterOptions={filterOptions}
        closeIcon={<div></div>}
        onInputChange={handleAutocompleteInputChange}
        onChange={handleAutocompleteChange}
        inputValue={props.value}
        renderInput={params =>  props.variant === 'search' ?
          SearchVariantField(params)  :
          StandardVariantField(params)
        }
        renderOption={(option: Unit, { inputValue }) => {
          const matches = match(option.assetid + ' (' + option.uuid.string() + ')', inputValue);
          const parts = parse(option.assetid + ' (' + option.uuid.string() + ')', matches);

          return (
            <div style={{ display: 'flex', alignItems: 'row', width: '100%' }}>
              {<DeviceIcon type={option} className={classes.loadingIcon} />}
              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 500 : 300, fontSize: '0.875rem' }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};