import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles } from "@material-ui/styles";

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = createStyles({
  loading: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
    height: '33vh',
  },
  loader: {
    textAlign: 'center'
  }
});

type Props = WithStyles<typeof styles> & {
  label?: string
};
type State = {};

export const Loading = withStyles(styles)(
  class extends Component<Props, State> {
  render() {
      const { classes } = this.props;
      const label = (this.props.label!=null) ? this.props.label : "Loading";
      return (<div className={classes.loading}>
        <div className={classes.loader}>
          <CircularProgress />
        <Typography>{label}</Typography>
        </div>
      </div>)
    }
  }
);
