import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function ZodiacIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5 4C4.5 2.89543 5.39543 2 6.5 2H17.5C18.6046 2 19.5 2.89543 19.5 4V20C19.5 21.1046 18.6046 22 17.5 22H6.5C5.39543 22 4.5 21.1046 4.5 20V4ZM8.78571 3.78947H15.2143V5.89474H8.78571V3.78947ZM17.3572 9.05263H6.64288V14.3158H17.3572V9.05263Z"/>
    </SvgIcon>
  );
}

export default ZodiacIcon;