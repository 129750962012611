import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function PVArrayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2 11H8V4H4C2.89543 4 2 4.89543 2 6V11ZM2 17C2 18.1046 2.89543 19 4 19H5H8V12H2V17ZM9 19H15V12H9V19ZM16 19H19H20C21.1046 19 22 18.1046 22 17V12H16V19ZM9 11H15V4H9V11ZM16 4V11H22V6C22 4.89543 21.1046 4 20 4H16Z" />
    </SvgIcon>
  );
}

export default PVArrayIcon;